// Used to validate the userType prop in several components
export const USER_TYPES = Object.freeze({
  ADMINISTRATOR: 'Administrator',
  REGIONAL_MANAGER: 'RegionalManager',
  BILLING_MANAGER: 'BillingManager',
  LOCATION_MANAGER: 'LocationManager',
});

// Mapping from user type to text to display for that user type
export const USER_TYPE_STRINGS = Object.freeze({
  ADMINISTRATOR: 'Admin',
  LOCATION_MANAGER: 'Location Manager',
  BILLING_MANAGER: 'Billing Manager',
  REGIONAL_MANAGER: 'Regional Manager',
});

// Return correctly formatted user type string from User Type
export const userTypeToUserTypeString = (type) => {
  switch (type) {
    case USER_TYPES.ADMINISTRATOR:
      return USER_TYPE_STRINGS.ADMINISTRATOR;
    case USER_TYPES.LOCATION_MANAGER:
      return USER_TYPE_STRINGS.LOCATION_MANAGER;
    case USER_TYPES.BILLING_MANAGER:
      return USER_TYPE_STRINGS.BILLING_MANAGER;
    case USER_TYPES.REGIONAL_MANAGER:
      return USER_TYPE_STRINGS.REGIONAL_MANAGER;
    default:
      throw new Error(`could not find user type: ${type}`);
  }
};

// Used to validate the organizationType prop in several components
export const ORG_TYPES = Object.freeze({
  BUSINESS: 'business',
  RECIPIENT: 'recipient',
});

// Used to style buttons, text, etc.; green for business organizations; blue for others
export const colors = {
  green: '#22B081',
  blue: '#0064B5',
};

export const TIMEZONES = Object.freeze({
  DUBAI: 'Asia/Dubai',
  DENVER: 'America/Denver',
  GMT: 'GMT',
  LOS_ANGELES: 'America/Los_Angeles',
});

export const DAY_NAMES = Object.freeze({
  FULL_WEEK: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  WEEKDAYS: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
});

export const CONTAINER_TYPES = Object.freeze({
  INDIVIDUAL: 'individual-meals',
  SINGLE_SERVING_ITEM: 'single-serving-items',
  CATERING: 'catering-trays',
  CATERING_HALF: 'half-size-catering-trays',
  BOX: 'boxes',
  DELI_QUART: '32oz-deli-containers',
  DELI_PINT: '16oz-deli-containers',
  OTHER: 'other',
});

// list is from https://www.britannica.com/topic/list-of-countries-1993160
export const COUNTRIES = Object.freeze([
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola",
  "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
  "Azerbaijan", "The Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus",
  "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina",
  "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
  "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
  "Chad", "Chile", "China", "Colombia", "Comoros", "Costa Rica",
  "Côte d’Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic",
  "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica",
  "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
  "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia",
  "Federated States of Micronesia", "Fiji", "Finland", "France", "Gabon",
  "The Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada",
  "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
  "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland",
  "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya",
  "Kiribati", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon",
  "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
  "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta",
  "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Moldova", "Monaco",
  "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
  "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger",
  "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan",
  "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru",
  "Philippines", "Poland", "Portugal", "Qatar", "Romania",
  "Republic of the Congo", "Russia", "Rwanda", "Saint Kitts and Nevis",
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
  "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
  "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
  "Somalia", "South Africa", "South Korea", "South Sudan", "Spain",
  "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan",
  "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago",
  "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine",
  "United Arab Emirates", "United Kingdom", "United States", "Uruguay",
  "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen",
  "Zambia", "Zimbabwe"])

// list is from https://tosbourn.com/list-of-countries-without-a-postcode/
export const COUNTRIES_WITHOUT_POSTAL_CODES = Object.freeze([
  "Angola", "Antigua and Barbuda", "The Bahamas", "Belize", "Benin",
  "Botswana", "Bolivia", "Burkina Faso", "Burundi", "Cameroon",
  "Central African Republic", "Comoros", "Côte d’Ivoire",
  "Democratic Republic of the Congo", "Djibouti", "Dominica", "East Timor",
  "Equatorial Guinea", "Eritrea", "Fiji", "The Gambia", "Gabon", "Ghana",
  "Grenada", "Guyana", "Kiribati", "Libya", "Malawi", "Mali", "Mauritania",
  "Nauru", "North Korea", "Qatar", "Republic of the Congo", "Rwanda",
  "Saint Kitts and Nevis", "Sao Tome and Principe", "Seychelles",
  "Sierra Leone", "Solomon Islands", "Suriname", "Syria", "Togo", "Tonga",
  "Tuvalu", "Uganda", "United Arab Emirates", "Vanuatu", "Yemen", "Zimbabwe"])

export const STATES = Object.freeze({
  "Australia": [
    'New South Wales', 'Victoria', 'Queensland', 'Western Australia',
    'South Australia', 'Tasmania', 'Australian Capital Territory',
    'Northern Territory',
  ],
  "Brazil": [
    'Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 'Distrito Federal',
    'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 'Mato Grosso do Sul',
    'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 'Piauí',
    'Rio de Janeiro', 'Rio Grande do Norte', 'Rio Grande do Sul', 'Rondônia',
    'Roraima', 'Santa Catarina', 'São Paulo', 'Sergipe', 'Tocantins',
  ],
  "Canada": [
    'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario',
    'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Northwest Territories',
    'Nunavut', 'Yukon',
  ],
  "China": [
    'Anhui', 'Beijing', 'Chongqing', 'Fujian', 'Gansu', 'Guangdong', 'Guangxi',
    'Guizhou', 'Hainan', 'Hebei', 'Heilongjiang', 'Henan', 'Hubei', 'Hunan',
    'Inner Mongolia', 'Jiangsu', 'Jiangxi', 'Jilin', 'Liaoning', 'Ningxia',
    'Qinghai', 'Shaanxi', 'Shandong', 'Shanghai', 'Shanxi', 'Sichuan',
    'Tianjin', 'Tibet', 'Xinjiang', 'Yunnan', 'Zhejiang',
  ],
  "France": [
    'Auvergne-Rhône-Alpes', 'Bourgogne-Franche-Comté', 'Brittany',
    'Centre-Val de Loire', 'Corsica', 'Grand Est', 'Hauts-de-France',
    'Île-de-France', 'Normandy', 'Nouvelle-Aquitaine', 'Occitanie',
    'Pays de la Loire', 'Provence-Alpes-Côte d’Azur',
  ],
  "Germany": [
    'Baden-Württemberg', 'Bavaria', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg',
    'Hesse', 'Lower Saxony', 'Mecklenburg-Vorpommern',
    'North Rhine-Westphalia', 'Rhineland-Palatinate', 'Saarland', 'Saxony',
    'Saxony-Anhalt', 'Schleswig-Holstein', 'Thuringia',
  ],
  "India": [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
    'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya',
    'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim',
    'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
    'West Bengal',
  ],
  "Japan": [
    'Hokkaido', 'Tohoku', 'Kanto', 'Chubu', 'Kinki', 'Chugoku', 'Shikoku', 'Kyushu',
  ],
  "Mexico": [
    'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche',
    'Chiapas', 'Chihuahua', 'Coahuila', 'Colima', 'Durango', 'Guanajuato',
    'Guerrero', 'Hidalgo', 'Jalisco', 'México', 'Michoacán', 'Morelos', 'Nayarit',
    'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí',
    'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz',
    'Yucatán', 'Zacatecas',
  ],
  "Russia": [
    'Central', 'Southern', 'Northwestern', 'Far Eastern', 'Siberian',
    'Urals', 'Volga', 'North Caucasian',
  ],
  "South Africa": [
    'Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal',
    'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape',
  ],
  "United Arab Emirates": [
    'Abu Dhabi', 'Dubai', 'Sharjah', 'Ajman', 'Umm Al Quwain',
    'Ras Al Khaimah', 'Fujairah',
  ],
  "United States": [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
    'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
    'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
    'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming',
  ]
});

export const CONTAINER_WEIGHT_UNITS = Object.freeze({
  PER_CONTAINER: 'Weight Per Container',
  TOTAL: 'Whole Entry',
});

export const PLAN_TYPES = Object.freeze({
  STARTER: 'starter',
  A_LA_CARTE: 'a_la_carte',
  MADE_TO_ORDER: 'made_to_order',
  ENTERPRISE: 'enterprise',
});

export const PLANS = Object.freeze({
  [PLAN_TYPES.STARTER]: {
    description: 'Pay $60 per pickup. No commitment.',
    descriptionLong: 'Not ready to commit? Only have surplus a few times a year? We got you. Replate’s Starter would be best in your situation.',
    bullets: [
      '1 Building Limit',
      '1 Floor Limit',
      'No Commitment',
    ],
    pricing: {
      basePickupPrice: 60,
    },
    colors: {
      background: 'light-green',
      text: 'dark-green',
    },
  },
  [PLAN_TYPES.A_LA_CARTE]: {
    description: 'Pay $75 per pickup. No commitment.',
    descriptionLong: 'Surplus is unpredictable - true. Sometimes it’s inevitable. If you know you’ll have extra food throughout the month, but can’t guarantee when, Replate A La Carte fits the bill.',
    bullets: [
      'Impact Metrics',
      'Additional floors & buildings by request',
      'No Commitment',
    ],
    pricing: {
      basePickupPrice: 75,
      additionalFloorPrice: 15,
      additionalBuildingPrice: 30,
    },
    colors: {
      background: 'warning',
      text: 'dark-green',
    },
  },
  [PLAN_TYPES.MADE_TO_ORDER]: {
    description: 'Customizable plan for orgs with complex food rescue needs.',
    descriptionLong: 'You’re committed to hitting sustainability goals and finding long term solutions…Replate Made to Order is for you! This plan is best suited for companies with several pickups a month, larger scale locations, and varying amounts of food.',
    bullets: [
      'Impact Metrics',
      'Access to our Source Reduction Tools',
      'Consulting (with an additional fee)',
      'Additional floors & buildings by request',
      'Yearly contract paid monthly',
    ],
    pricing: {
      basePickupPrice: 65,
      additionalFloorPrice: 10,
      additionalBuildingPrice: 20,
    },
    isSubscription: true,
    colors: {
      background: 'dark-green',
      text: 'white',
    },
  },
  [PLAN_TYPES.ENTERPRISE]: {
    pricing: {},
    colors: {
      background: 'dark-red',
      text: 'white',
    },
  },
});
